@import 'variables';


$kendo-switch-label-display: inline;
$kendo-switch-label-text-transform: capitalize;

$kendo-switch-on-bg: $title-color;

$kendo-switch-on-focused-bg: lighten($title-color, 8);

$kendo-switch-on-hovered-bg: lighten($title-color, 8);

$kendo-switch-on-handle-bg: white;
$kendo-switch-on-handle-shadow: null;

$kendo-switch-off-handle-bg: white;
// Reducing splitter line heights
$splitter-line-height: 1.5;

@import "node_modules/@progress/kendo-theme-material/dist/all.scss";
@import "node_modules/@progress/kendo-font-icons/dist/index.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
  color: $gray-color;

  p {
    margin-bottom: 0;
  }
}

.k-textbox {
  border-color: rgba(0, 0, 0, 0.54);
}

.k-input-solid, .k-picker-solid {
  background-color: white;
}

.k-input-solid:disabled, .k-input-solid[disabled], .k-input-solid.k-disabled {
  background-color: white;
  border-color: rgba(0, 0, 0, 0.54);
  border-style: dashed;
}

.k-list-item.k-selected {
  background: rgba(0, 0, 0, 0.04);
}

.k-list-md .k-list-item {
  padding: 2px 24px;

  .k-checkbox.k-rounded-md {
    border-radius: 0;
  }

  .selected {
    color: #e51a5f;
  }
}

.k-switch-label-on {
  left: 8px !important;
  font-size: 10px;
}

.k-switch-label-off {
  right: 8px !important;
  font-size: 10px;
}

.k-switch-on, .k-switch-off {
  color: white;
}

.k-switch-on .k-switch-thumb, .k-switch-off .k-switch-thumb {
  background-color: white;
}

.k-switch-on .k-switch-track {
  background-color: #336abe;
}

.k-switch-sm {
  width: 4em;

  .k-switch-track {
    height: 2rem;
    line-height: 2;
    padding: 2px;
  }

  .k-switch-thumb {
    width: 1.5em;
    height: 1.5em;
  }

  &.k-switch-off .k-switch-thumb-wrap {
    left: 16px;
  }

  &.k-switch-on .k-switch-thumb-wrap {
    left: calc(100% - 16px);
  }
}

.k-pager-sizes .k-picker .k-input-inner {
  font-size: 14px;
}

.k-popup .k-menu-group .k-menu-item .k-menu-link {
  padding: 4px 24px;
  font-size: 12px;
}

.k-treeview-leaf {
  &.k-selected {
    color: white;
    background-color: #375799;
  }
}

.k-tabstrip-items .k-item.k-active {
  font-weight: bold;
  color: $secondary-color;
}

.ml-auto {
  margin-left: auto;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-60 {
  margin-top: 60px;
}

.ml-15 {
  margin-left: 15px;
}

.gray-color {
  color: $gray-color;
}

.secondary-color {
  color: $secondary-color;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.form-label {
  color: $gray-color;
  font-family: $roboto;
  font-size: 14px;
  font-weight: 500;
}

.prism-link {
  color: $secondary-color;
  font-size: 14px;
}

.page-title {
  height: 29px;
  width: 189px;
  color: #000000;
  font-family: $monserrat;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
}

.blank-input {
  border: none;
  color: $gray-color;

  &:focus {
    border: none;
    color: $gray-color;
    outline: none;
  }
}

.prism-grid.k-grid table tr:hover {
  color: $gray-color;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4)
}

.prism-grid.k-grid tr.k-alt {
  background-color: rgb(246, 246, 246);

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
}

.prism-grid.k-grid td {
  white-space: inherit;
  cursor: pointer;
}

.k-grid tr td {
  padding: 8px 24px;
}

.k-grid tr.even {
  background-color: #FFFFFF;
}

.k-grid .rowaction-container {
  display: none;
}

.k-grid table tr:hover {
  color: $secondary-color;

  .rowaction-container {
    display: flex;
  }
}

.fullWidth {
  width: 100% !important;
}

.form-label {
  font-size: 14px;
  color: $gray-color;
}

.small-text {
  font-size: 11px;
  font-weight: 300;
}

.tab-strip-no-space > .k-content {
    padding: 5px;
}

.dropdown-container {
  .k-combobox .k-dropdown-wrap {
    border-width: 1px;
    height: 30px;
    border-radius: .25rem;
    font-size: 14px;
    border-color: $border-color;

    .k-input {
      border-color: #ced4da !important;
      border-right: 1px solid;
      padding-left: 5px;
      height: 30px;
    }
  }

  .k-combobox .k-dropdown-wrap:hover, .k-combobox .k-dropdown-wrap.k-state-hover {
    border-color: #ced4da;
  }

  .k-combobox .k-dropdown-wrap .k-clear-value {
    font-size: 14px;
    top: 6px;
  }
}

.k-tabstrip-items .k-item {
  text-transform: initial;

  &.k-state-active {
    color: $secondary-color !important;
    font-weight: 700;

    &::after {
      border-color: $secondary-color !important;
    }
  }
}

.section-header-container .k-stepper .k-step-label .k-step-text {
  font-size: 12px;
  color: $gray-color;
  max-width: calc(10em - (5px + 4px));
}

.section-header-container .k-stepper .k-step-current .k-step-indicator {
  border-color: $secondary-color;
  color: #ffffff;
  background-color: $secondary-color;
}

.section-header-container .k-stepper .k-step-current:hover .k-step-indicator {
  border-color: $secondary-color;
  color: #ffffff;
  background-color: $secondary-color;
}

.section-header-container .k-stepper .k-step-indicator {
  border-color: $dark-yellow;
  color: $dark-yellow;
}

.k-stepper .k-step-done .k-step-indicator {
  border-color: #3f51b5;
  color: #ffffff;
  background-color: #3f51b5;
}

.section-header-container .k-stepper .k-step.k-step-disabled .k-step-indicator, .k-stepper .k-step:disabled .k-step-indicator {
  border-color: $dark-yellow;
  color: $dark-yellow;
}

.roboto-reg-gray {
  font-family: $roboto;
  font-size: 14px;
  font-weight: 400;
  color: $gray-color;
}

.roboto-small-gray {
  font-family: $roboto;
  font-size: 12px;
  font-weight: 400;
  color: $gray-color;
}

.roboto-med-gray, .label {
  font-family: $roboto;
  font-size: 14px;
  font-weight: 500;
  color: $gray-color;
}

.red-label {
   font-family: $roboto;
   font-size: 14px;
   font-weight: 500;
   color: $error-color;
 }

.monserrat-bold-gray-4 {
  font-family: $monserrat;
  font-size: 14px;
  font-weight: 600;
  color: $gray-fourth;
}

.monserrat-reg-gray {
  font-family: $monserrat;
  font-size: 14px;
  font-weight: 400;
  color: $gray-color;
}

.section-header {
  font-family: $monserrat;
  font-size: 16px;
  font-weight: 600;
  color: $gray-color;
}

.section-header-med {
  font-family: $monserrat;
  font-size: 19px;
  font-weight: 500;
  color: $gray-color;
}

.section-content, .section-content-header {
  font-family: $monserrat;
  font-size: 14px;
  font-weight: 500;
  color: $gray-color;
}

.section-label {
  font-family: $roboto;
  font-size: 14px;
  font-weight: 700;
  color: $gray-color;
}

.error-notification.k-notification-error {
  background-color: $error-color;
  z-index: 1000;
}

.success-notification.k-notification-error {
  background-color: $active-green;
  z-index: 1000;
}

.no-grid-content {

  text-align: center;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  .no-data-img {
    height: 100%;
    width: auto;
    float: contour;
  }

  .message-header {
    font-family: $monserrat;
    font-size: 19px;
    font-weight: 500;
    color: $gray-fourth;
  }

  .message-description {
    white-space: normal;
  }
}

.menu-item-hidden, .prism-portal .hidden-content {
  display: none !important;
}

.disabled-content {
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
  cursor: none;
}

.k-notification-group {
  z-index: 10009;
}

:root {
  .mat-icon {
    width: 1em;
    height: 1em;
    display: inline-flex;
    margin-left: .25rem;
    outline: 0;
  }

  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.comments-section {
  width: 100%;
}

.k-textarea > .k-input {
  max-height: 100px;
  overflow-y: auto !important;
}

col.k-sorted, th.k-sorted {
  background: none;
}

.stretch-side-nav {
  min-height: calc(100vh - 326px);
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}
