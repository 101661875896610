@forward "_variables.scss";

@use "_font.scss" as *;
@use "_variables.scss" as *;
@use "_icon-list.scss" as *;

@mixin kendo-font-icon-styles() {

    // Keep this as a root selector, because it doesn't work when nested inside selector
    @at-root {
        @font-face {
            font-family: $ki-font-family;
            font-style: normal;
            font-weight: normal;
            src: url( "#{$ki-font-url}" ) format( "truetype" );
        }
    }


    // Font icon
    .k-font-icon {
        width: 1em;
        height: 1em;
        outline: 0;
        font-size: $ki-icon-size;
        font-family: $ki-font-family;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        speak: none;
        text-transform: none;
        text-decoration: none;
        flex-shrink: 0;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        position: relative;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    // Empty icon
    .k-i-none::before {
        display: none !important; // stylelint-disable-line
    }


    // Icon sizes
    .k-icon-xs {
        font-size: $ki-icon-size-xs;
    }
    .k-icon-sm {
        font-size: $ki-icon-size-sm;
    }
    .k-icon-md {
        font-size: $ki-icon-size-md;
    }
    .k-icon-lg {
        font-size: $ki-icon-size-lg;
    }
    .k-icon-xl {
        font-size: $ki-icon-size-xl;
    }
    .k-icon-xxl {
        font-size: $ki-icon-size-xxl;
    }
    .k-icon-xxxl {
        font-size: $ki-icon-size-xxxl;
    }


    // Flip
    .k-flip-h {
        transform: scaleX( -1 );
    }
    .k-flip-v {
        transform: scaleY( -1 );
    }
    .k-flip-h.k-flip-v,
    .k-flip-both {
        transform: scale( -1, -1 );
    }


    // Rotate
    @each $index, $rotate in $ki-rotate-map {
        .k-rotate-#{$index} {
            transform: rotate( #{$rotate} );
        }
    }


    @include kendo-icon-list();

}
